import React, { useState } from "react";
import "../styles/Form.scss";
import FormInput from "./FormInput";
import Button from "./Button";
import ThankYouModal from "./ThankYouModal";
import TermsModal from "./TermsModal";
import axios from "axios";
import { key } from "../API";
import toast from "react-hot-toast";

const Form = ({ openModal }) => {
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [termsCondition, setTermsCondition] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    imeiNumber: "",
    serialNumber: "",
    storeDMSCode: "",
    modelPurchased: "",
    invoiceNumber: "",
    emailID: "",
  });

  const handleCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
    setErrors((prevErrors) => {
      const { terms, ...rest } = prevErrors;
      return e.target.checked ? rest : { ...rest, terms: "You must accept the terms and conditions" };
    });
  };

  // Modified validation function for individual fields
  const validateField = (name, value) => {
    let error = null;

    if (name !== "storeDMSCode" && name !== "imeiNumber" && name !== "serialNumber" && !value.trim()) {
      switch (name) {
        case "fullName":
          error = "Full Name is required";
          break;
        case "mobileNumber":
          error = "Mobile Number is required";
          break;
        case "modelPurchased":
          error = "Model Purchased is required";
          break;
        case "invoiceNumber":
          error = "Invoice Number is required";
          break;
        case "emailID":
          error = "Email ID is required";
          break;
        default:
          error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
      }
    } else {
      switch (name) {
        case "emailID":
          if (value && !/\S+@\S+\.\S+/.test(value)) {
            error = "Invalid email format";
          }
          break;
        case "mobileNumber":
          if (value) {
            if (/[a-zA-Z]/.test(value)) {
              error = "Alphabets are not allowed in Mobile Number";
            } else if (!/^\d{10}$/.test(value)) {
              error = "Mobile Number must be exactly 10 digits";
            }
          }
          break;
        default:
          break;
      }
    }

    return error;
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const newErrors = {};

  //   // Validate all fields on submit
  //   Object.keys(formData).forEach((key) => {
  //     const error = validateField(key, formData[key]);
  //     if (error) {
  //       newErrors[key] = error;
  //     }
  //   });

  //   // Check if at least one of IMEI or Serial Number is filled
  //   if (!formData.imeiNumber.trim() && !formData.serialNumber.trim()) {
  //     newErrors.imeiNumber = "Either IMEI Number or Serial Number is required";
  //     newErrors.serialNumber = "Either IMEI Number or Serial Number is required";
  //   }

  //   // Additional validations
  //   if (!termsAccepted) {
  //     newErrors.terms = "You must accept the terms and conditions";
  //   }

  //   setErrors(newErrors);
  //   if (Object.keys(newErrors).length === 0) {
  //     setLoader(true);
  //     const formDataa = new FormData();
  //     formDataa.append("QAs[0][question_id]", "1222");
  //     formDataa.append("QAs[0][answer]", formData.fullName);
  //     formDataa.append("QAs[1][question_id]", "1223");
  //     formDataa.append("QAs[1][answer]", "91");
  //     formDataa.append("QAs[2][question_id]", "1224");
  //     formDataa.append("QAs[2][answer]", formData.mobileNumber);
  //     formDataa.append("QAs[3][question_id]", "1225");
  //     formDataa.append("QAs[3][answer]", formData.emailID);
  //     formDataa.append("QAs[4][question_id]", "1226");
  //     formDataa.append("QAs[4][answer]", formData.imeiNumber);
  //     formDataa.append("QAs[5][question_id]", "1259"); // Added new field for Serial Number
  //     formDataa.append("QAs[5][answer]", formData.serialNumber);
  //     formDataa.append("QAs[6][question_id]", "1260");
  //     formDataa.append("QAs[6][answer]", formData.storeDMSCode);
  //     formDataa.append("QAs[7][question_id]", "1261");
  //     formDataa.append("QAs[7][answer]", formData.modelPurchased);
  //     formDataa.append("QAs[8][question_id]", "1262");
  //     formDataa.append("QAs[8][answer]", formData.invoiceNumber);
  

  //     axios
  //       .post(key || "https://backend.wowsly.com/api/events/52/registrationform/answer", formDataa, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: "Bearer 5541|6zHhTqgTvW65y7QlGIqRGgeVOU1MBxv7yXP0CeYm",
  //         },
  //       })
  //       .then(function (response) {
  //         console.log(response,"responseee")
  //         if (response.status === 200) {
  //           console.log("if blockkkk")
  //           setShowThankYouModal(true);
  //           setFormData({
  //             fullName: "",
  //             mobileNumber: "",
  //             imeiNumber: "",
  //             serialNumber: "",
  //             storeDMSCode: "",
  //             modelPurchased: "",
  //             invoiceNumber: "",
  //             emailID: "",
  //           });
  //           setTermsAccepted(false);
  //           setLoader(false);
  //           toast.success("Form submitted successfully");
  //           setErrors({});
  //         }else {
  //           console.log("else blockkkk")
  //           toast.error("Something went wrong. Please try again later.");
  //           setLoader(false);
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         toast.error("Something went wrong. Please try again later.");
  //         setLoader(false);
  //       });
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
  
    // Validate all fields on submit
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
  
    // Check if at least one of IMEI or Serial Number is filled
    if (!formData.imeiNumber.trim() && !formData.serialNumber.trim()) {
      newErrors.imeiNumber = "Either IMEI Number or Serial Number is required";
      newErrors.serialNumber = "Either IMEI Number or Serial Number is required";
    }
  
    // Additional validations
    if (!termsAccepted) {
      newErrors.terms = "You must accept the terms and conditions";
    }
  
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoader(true);
      const formDataa = new FormData();
          formDataa.append("QAs[0][question_id]", "1222");
          formDataa.append("QAs[0][answer]", formData.fullName);
          formDataa.append("QAs[1][question_id]", "1223");
          formDataa.append("QAs[1][answer]", "91");
          formDataa.append("QAs[2][question_id]", "1224");
          formDataa.append("QAs[2][answer]", formData.mobileNumber);
          formDataa.append("QAs[3][question_id]", "1225");
          formDataa.append("QAs[3][answer]", formData.emailID);
          formDataa.append("QAs[4][question_id]", "1226");
          formDataa.append("QAs[4][answer]", formData.imeiNumber);
          formDataa.append("QAs[5][question_id]", "1259"); 
          formDataa.append("QAs[5][answer]", formData.serialNumber);
          formDataa.append("QAs[6][question_id]", "1260");
          formDataa.append("QAs[6][answer]", formData.storeDMSCode);
          formDataa.append("QAs[7][question_id]", "1261");
          formDataa.append("QAs[7][answer]", formData.modelPurchased);
          formDataa.append("QAs[8][question_id]", "1262");
          formDataa.append("QAs[8][answer]", formData.invoiceNumber);
          formDataa.append("dialing_code", "91");
          formDataa.append("mobile", formData.mobileNumber);
  
      try {
        const response = await axios.post(
          key || "https://backend.wowsly.com/api/events/52/registrationform/answer",
          formDataa,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer 5541|6zHhTqgTvW65y7QlGIqRGgeVOU1MBxv7yXP0CeYm",
            },
          }
        )
        if (response?.status === 200) {
          console.log("Form submitted successfully");
          setShowThankYouModal(true);
          setFormData({
            fullName: "",
            mobileNumber: "",
            imeiNumber: "",
            serialNumber: "",
            storeDMSCode: "",
            modelPurchased: "",
            invoiceNumber: "",
            emailID: "",
          });
          setTermsAccepted(false);
          setErrors({});
        } else {
          console.log("Unexpected response status:", response?.status);
          toast.error("Something went wrong. Please try again later.");
        }
      } catch (error) {
        console.error("API Error:", error);
        toast.error("Something went wrong. Please try again later.");
      } finally {
        setLoader(false);
      }
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear errors for both IMEI and Serial Number fields when either is filled
    if (name === 'imeiNumber' || name === 'serialNumber') {
      if (value.trim() || (name === 'imeiNumber' ? formData.serialNumber : formData.imeiNumber).trim()) {
        setErrors(prevErrors => {
          const { imeiNumber, serialNumber, ...rest } = prevErrors;
          return rest;
        });
      }
    } else {
      // Validate other fields on change
      const fieldError = validateField(name, value);
      setErrors((prevErrors) => {
        if (fieldError) {
          return { ...prevErrors, [name]: fieldError };
        } else {
          const { [name]: removedError, ...rest } = prevErrors;
          return rest;
        }
      });
    }
  };

  const closeThankYouModal = () => {
    setShowThankYouModal(false);
  };

  return (
    <React.Fragment>
      {!termsCondition ? (
        <div className="form-container">
          {/* <h2>Participation Form</h2>
          <form className="participation-form" onSubmit={handleSubmit}>
            <FormInput
              label="Full Name*"
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              errors={errors.fullName}
            />
            <FormInput
              label="Mobile Number*"
              type="text"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              errors={errors.mobileNumber}
              pattern="\d*"
              inputMode="numeric"
            />
            <FormInput
              label="IMEI Number"
              type="text"
              name="imeiNumber"
              value={formData.imeiNumber}
              onChange={handleInputChange}
              errors={errors.imeiNumber}
            />
            <FormInput
              label="Serial Number"
              type="text"
              name="serialNumber"
              value={formData.serialNumber}
              onChange={handleInputChange}
              errors={errors.serialNumber}
            />
            <FormInput
              label="Store DMS code (To be provided by store)"
              type="text"
              name="storeDMSCode"
              value={formData.storeDMSCode}
              onChange={handleInputChange}
            />
            <FormInput
              label="Model Purchased*"
              type="text"
              name="modelPurchased"
              value={formData.modelPurchased}
              onChange={handleInputChange}
              errors={errors.modelPurchased}
            />
            <FormInput
              label="Invoice Number*"
              type="text"
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleInputChange}
              errors={errors.invoiceNumber}
            />
            <FormInput
              label="Email ID*"
              type="email"
              name="emailID"
              value={formData.emailID}
              onChange={handleInputChange}
              errors={errors.emailID}
            />
            <div className="checkbox">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "0px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <input type="checkbox" checked={termsAccepted} onChange={handleCheckboxChange} />
                  <label className="terms-condition-text">
                    I accept the{" "}
                    <span onClick={() => setTermsCondition(true)} className="terms-link">
                      terms & conditions
                    </span>
                    .
                  </label>
                </div>
                {errors.terms && (
                  <p style={{ color: "red", margin: "0", fontSize: "10px" }} className="error-message">
                    {errors.terms}
                  </p>
                )}
              </div>
            </div>
            <Button type="submit" label={loader ? "Submitting.." : "Submit"} />
          </form> */}
          <h1 style={{textAlign:"center", color:"red"}}>Registration Closed!</h1>
          {showThankYouModal && <ThankYouModal show={showThankYouModal} handleClose={closeThankYouModal} />}
        </div>
      ) : (
        <></>
        // <TermsModal setTermsCondition={setTermsCondition} />
      )}
    </React.Fragment>
  );
};

export default Form;