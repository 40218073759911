import React from "react";
import "../styles/Header.scss";
import logo from "../assets/heroimagee-min.jpg";
const Header = () => {
  return (
    <div className="header">
      <img src={logo} />
      {/* <h1>Sona jeeto</h1> */}
    </div>
  );
};

export default Header;
