import React from "react";
import "../styles/ThankYouModal.scss"; // Modal-specific styles
import Modal from "react-modal";
import Check from "../assets/check_circle.jpg";
const ThankYouModal = ({ show, handleClose }) => {
  if (!show) return null;

  return (
    <Modal
      isOpen={show}
      onRequestClose={handleClose}
      className="custom-modal-content"
      overlayClassName="custom-modal-overlay"
    >
      <div className="right-modal-overlay">
        <div className="modal-content">
          <div className="modal-icon">
            <img src={Check} alt="check_box" height={60} width={60} />
          </div>
          <h2>Thank You!</h2>
          <p>
            For participating in the awesome promotion. Get lucky and win
            exciting prizes. T&C apply.
          </p>
          <button className="ok-btn" onClick={handleClose}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ThankYouModal;
